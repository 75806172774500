body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: hsla(158, 100%, 33%, 1);
  background: linear-gradient(90deg, hsla(158, 100%, 33%, 1) 0%, hsla(158, 74%, 45%, 1) 100%);
  background: -moz-linear-gradient(90deg, hsla(158, 100%, 33%, 1) 0%, hsla(158, 74%, 45%, 1) 100%);
  background: -webkit-linear-gradient(90deg, hsla(158, 100%, 33%, 1) 0%, hsla(158, 74%, 45%, 1) 100%);
  filter: progid: DXImageTransform.Microsoft.gradient( startColorstr="#00A86B", endColorstr="#1DC688", GradientType=1 );
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
