:root {
    --icon-fill: black;
}

[data-theme="dark"] {
    --icon-fill: white;
}

.icon {
    fill: var(--icon-fill);
}

.icon.icon-primary {
    fill: white;
}

.icon.disabled {
    fill: #a0a0a0;
}

.icon-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

.icon-container.hover {
    padding: 10px;
    cursor: pointer;
    border-radius: 500px;
}

.icon-container.capsule {
    padding: 10px 20px;
    border-radius: 500px;
}

.icon-container.hover:hover {
    background-color: var(--hover-color);
}

.icon-container.hover:active {
    background-color: var(--tap-down-color);
}

.icon.xxxxsmall {
    width: 12px;
    height: 12px;
}

.icon.xxxsmall {
    width: 16px;
    height: 16px;
}

.icon.xxsmall {
    width: 18px;
    height: 18px;
}

.icon.xsmall {
    width: 22px;
    height: 22px;
}

.icon.small {
    width: 28px;
    height: 28px;
}

.icon.medium {
    width: 42px;
    height: 42px;
}

.icon.large {
    width: 56px;
    height: 56px;
}

.icon.xlarge {
    width: 72px;
    height: 72px;
}

.icon.xxlarge {
    width: 98px;
    height: 98px;
}

.icon.xxxlarge {
    width: 164px;
    height: 164px;
}


.icon.icon-color-primary {
    fill: var(--primary-color);
}

.icon.icon-color-green {
    fill: #40df40;
}

.icon.icon-color-droid {
    fill: #3DDC84;
}

.icon.icon-color-html {
    fill: #ff6944;
}

.icon.icon-color-black {
    fill: black;
}

.icon.icon-color-white {
    fill: white;
}

.icon.icon-color-red {
    fill: red;
}

.icon.icon-color-orange {
    fill: orange;
}

.icon.icon-color-blue {
    fill: blue;
}