:root {
    --button-font-color: black;
    --button-outline-color: #b4c1dcd5;
}

[data-theme="dark"] {
    --button-font-color: white;
}

.button-group {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.button {
    font-size: 1.0em;
    background-color: var(--primary-color);
    border: none;
    color: var(--button-font-color);
    padding: 10px 22px;
    text-align: center;
    text-decoration: none;
    display: inline-block;
    font-size: 16px;
    font-weight: bold;
    margin: 4px 2px;
    border: 1px solid transparent;
    cursor: pointer;
    border-radius: 500px;
}

.needs-min {
    min-width: 80px;
}

.primary {
    background: #FF6F61;
    color: white;
}

.primary:hover {
    background: #fe796d;
}

.primary:active {
    background: #fe796d;
}

.primary:disabled {
    background: none;
}

.button:hover {
    background-color: #fe796d;
}

.button:active {
    background-color: #fe796d;
}

.button:disabled {
    background-color: #b0b0b0;
}

.button-icon {
    font-size: 1.0em;
    width: 14x;
    height: 14px;
}

.outline {
    background-color: transparent;
    border: 2px solid var(--button-outline-color);
    color: var(--button-font-color);
}

.destructive {
    background-color: transparent;
    border: 1px solid #ff0000;
    color: var(--button-font-color);;
}

.destructive:hover {
    background-color: #ff0000;
    color: var(--button-font-color);;
}

.destructive:active {
    background-color: #ff0000;
    color: var(--button-font-color);;
}

.destructive:disabled {
    background-color: #b0b0b0;
    border-color: transparent;
    color: var(--button-font-color);;
}

.inline {
    font-size: 0.8em;
    font-weight: 700;
    padding: 10px 20px;
}

.outline:hover {
    background-color: var(--hover-color);
}

.outline:active {
    background-color: var(--tap-down-color);
}

.outline:disabled {
    background-color: #b0b0b0;
    color: var(--button-font-color);;
}

.none {
    background-color: transparent;
}

.none:hover {
    background-color: var(--hover-color);
}

.none:active {
    background-color: var(--tap-down-color);
}

.none:disabled {
    background-color: transparent;
    color: #b0b0b0;
}

.button.selected {
    background-color: var(--primary-color);
    color: white;
}

.spinner {
    animation-name: spin;
    animation-duration: 500ms;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    height: 22px;
    width: 22px;
}

.spinner-inline {
    height: 18px;
    width: 18px;
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}